import React from 'react'
import Head from 'next/head'

import NotFoundPage from 'src/component/Pages/NotFound'

export default function Index() {
  return (
    <>
      <Head>
        <title>Content not found - Citygate Portal</title>
      </Head>
      <NotFoundPage />
    </>
  )
}
