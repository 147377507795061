import React from 'react'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Container from '@/component/Primitive/Container'
import Prose from '@/component/Primitive/Prose'
import SplitPage from '@/component/Landmark/SplitPage'
import Stack from '@/component/Primitive/Stack'
import Type from '@/component/Primitive/Type'

const NotFoundPage = () => (
  <SplitPage>
    <Container gutter center>
      <Stack gap="l">
        <Type as="h1" size="xl">
          Content not found
        </Type>
        <Prose>We couldn't find the content you were looking for.</Prose>
        <ButtonStandard to="/">Home</ButtonStandard>
      </Stack>
    </Container>
  </SplitPage>
)

export default NotFoundPage
