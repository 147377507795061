import React from 'react'
import { node } from 'prop-types'
import contact from '@/data/contact'

import styles from './SplitPage.module.scss'

import LogoLockup from '@/component/Primitive/LogoLockup'
import Type from '@/component/Primitive/Type'

const SplitPage = ({ children, sidebarContent }) => (
  <div className={styles.SplitPage}>
    <header className={styles.SplitPagePrimary}>
      <div className={styles.SplitPageHeader}>
        <LogoLockup as="h1" title="Customer Portal" logo="mycitygate" />
      </div>
      <div className={styles.SplitPageIntro}>{sidebarContent}</div>
      <div className={styles.SplitPageFooter}>
        <Type size="s">
          Need assistance?
          <br />{' '}
          <a href={contact.phone.standard.href}>
            Call us on {contact.phone.standard.display}
          </a>
        </Type>
      </div>
    </header>
    <main id="content" className={styles.SplitPageSecondary}>
      <div className={styles.SplitPageContent}>{children}</div>
    </main>
  </div>
)

SplitPage.propTypes = {
  children: node.isRequired,
  sidebarContent: node.isRequired
}

export default SplitPage
